.hero-section {
    height: 90vh;
}

.intro-video {
    /*filter: brightness(30%);*/
}

.hero-section {
    position: relative;
    overflow: hidden;
}

.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}

.intro-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.brightness-transition {
    transition: filter 1s ease; /* Adjust the duration (2s) and timing function (ease) as needed */
}

.empty-section-video {
    height: 75vh;
}


.header-menu-item {
    @apply relative;
    text-decoration: none;
}

.header-menu-item::after {
    @apply bg-primary-500 absolute w-full h-0.5 bottom-0 left-0;
    content: "";
    transform-origin: center;
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
}

.header-menu-item:hover::after {
    transform: scaleX(1);
}


.hero-hr {
    @apply w-full border-primary-500 my-4;
}

.footer {
    @apply bg-black text-white;
}

.carousel-legend {
    @apply w-full md:w-2/5 absolute bottom-0 left-0 my-12 mx-4 md:mx-24 text-left;
}

.carousel-image {
    filter: brightness(0.5);
    height: 80vh;
    @apply relative object-cover;
}

.carousel-image-room {
    height: 75vh;
    filter: brightness(0.75);
    @apply relative object-cover;
}

@media (min-width: 768px) {
    .complete-bundle {
        @apply transition-all z-10;
        transform: scale(1.225);
    }

    .bundle-card {
        @apply transition-all;
    }

    .bundle-card:hover {
        transform: scale(1.05);
    }

    .complete-bundle:hover {
        transform: scale(1.275);
    }
}

.highlight-picture-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75));
    @apply z-50 h-screen w-full top-0 left-0 fixed flex items-center justify-center;
}

.overlay-cross {
    @apply text-white text-2xl absolute top-4 right-8 cursor-pointer;
}

.sub-menu > a,
.sub-menu button {
    @apply cursor-pointer w-full py-6 px-4 transition-all flex flex-col justify-center items-center border border-solid border-gray-50;
}

.sub-menu .menu-title {
    @apply uppercase text-sm font-semibold md:text-xl px-4;
}

.sub-menu > a:hover,
.sub-menu button:hover {
    @apply bg-primary-500 text-white border-primary-500;
}

.divider {
    @apply w-0.5 bg-gray-300 my-6;
}

.contact-float-button {
    @apply cursor-pointer transition-all text-white z-50
    rounded-full shadow-2xl p-4 text-4xl flex justify-center items-center
    fixed bottom-4 right-4 md:bottom-6 md:right-6 bg-primary-500 w-16 h-16;
}

.contact-float-button:hover {
    @apply bg-primary-300;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

.expanded {
    animation: slide-in 1s;
}

.form-success-popup {
    @apply bg-gray-100 rounded-full shadow-2xl
    bottom-4 border-0 border-solid border-fourth-500
    fixed z-10 p-2 px-4 text-lg;
    left: 50%;
    transform: translateX(-50%);

    .form-success-popup-inner {
        @apply flex h-full items-center;
    }
}

@media (prefers-color-scheme: dark) {

}

img {
    /*overflow-clip-margin: unset;*/
}

.half-bg {
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
    url("./assets/images/free-weight-room2-2000w.avif");
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .concept-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("assets/images/free-weight-room3-800w.avif");
        height: 80vh;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .membership-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
        url("assets/images/machine-room21-800w.avif");
        height: 80vh;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .rooms-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
        url("assets/images/free-weight-room13-800w.avif");
        height: 80vh;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .faq-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
        url("assets/images/general-800w.avif");
        height: 80vh;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .pictures-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)),
        url("assets/images/free-weight-room4-800w.avif");
        height: 80vh;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .bg-personal-training {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("assets/images/general3-800w.avif");
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: scroll;
    }

    .contact-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url("assets/images/cardio-room1-1000w.avif");
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .own-room-section {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("assets/images/free-weight-room2-800w.avif");
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media (min-width: 768px) {
    /*TODO generieke class maken via gpt*/
    .concept-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("assets/images/free-weight-room3-2000w.avif");
        height: 80vh;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .membership-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
        url("assets/images/machine-room21-2000w.avif");
        height: 80vh;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .rooms-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
        url("assets/images/free-weight-room13-2000w.avif");
        height: 80vh;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .faq-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
        url("assets/images/general-2000w.avif");
        height: 80vh;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .pictures-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)),
        url("assets/images/free-weight-room4-2000w.avif");
        height: 80vh;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }


    .bg-personal-training {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("assets/images/general3-2000w.avif");
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }

    .contact-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("assets/images/cardio-room2-2000w.avif");
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .own-room-section {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("assets/images/free-weight-room2-2000w.avif");
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }
}