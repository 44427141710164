@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

.default-container {
    @apply mx-auto max-w-screen-xl py-16 md:py-24 px-8;
}

.medium-container {
    @apply mx-auto max-w-screen-lg py-14 md:py-20 px-8
}

.small-container {
    @apply max-w-4xl px-4 mx-4 md:mx-auto py-12 md:py-20;
}

.grid-three-sections {
    @apply block md:grid grid-cols-3 gap-x-8 space-y-8 md:space-y-0;
}

.primary-hr {
    @apply w-1/4 border-primary-500 border mt-1;
}

.primary-button {
    @apply uppercase transition-colors
    rounded-full bg-primary-500 px-6 py-2
    shadow-2xl font-semibold text-white
    focus:ring-4 focus:ring-primary-300;

    &:hover {
        @apply bg-primary-300 text-white;
    }
}

.secondary-button {
    @apply text-black uppercase transition-colors rounded-full bg-white px-6 py-2 shadow-2xl border-0 border-solid border-black;

    &:hover {
        @apply bg-gray-200 text-black;
    }
}

.white-card {
    @apply bg-white shadow-2xl p-8 rounded;
}

.black-card {
    @apply bg-black shadow-2xl p-8 rounded text-white;
}

.primary-gradient {
    @apply bg-gradient-to-r from-primary-700 to-primary-400;
}

.zoom {
    @apply transition-transform;
}

.active-link {
    @apply text-primary-300;
}

.zoom:hover {
    transform: scale(1.05);
}

@layer base {
    /*scrolling offset for sections, height of header*/
    section {
        scroll-margin-top: 4rem;
    }

    h1 {
        @apply font-poppins text-white tracking-wide uppercase text-5xl md:text-6xl xl:text-7xl font-semibold;
        text-wrap: balance;
    }

    h2 {
        @apply font-poppins uppercase text-3xl md:text-5xl font-semibold;
    }

    h3 {
        @apply font-poppins uppercase text-2xl md:text-4xl font-semibold;
    }

    h4 {
        @apply font-poppins uppercase text-xl md:text-3xl font-semibold;
    }

    p {
        @apply text-lg text-gray-700 md:text-xl tracking-wider;
    }

    a {
        @apply transition-all;
    }

    a:hover {
        @apply text-primary-200;
    }

    .package-list {
        list-style: none; /* Remove default bullets */
    }

    .package-list li::before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        @apply text-primary-500;
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
}
